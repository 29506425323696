import React from "react";
import {INWIN_PORTAL_LOGIN_URL, INWIN_PORTAL_SIGNUP_URL} from "config";
import { Box, Button, Typography, Link, Grid } from "@material-ui/core";
import { Image } from "../../shared/components";
import { useStyles } from "./styles";

export const AppsAndRegister = () => {
  const styles = useStyles();

  return (
    <Box textAlign="center" className={styles.appsContainer}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Box display="flex" justifyContent="center" alignItems="center" paddingBottom={1}>
            <Button variant="outlined" href={INWIN_PORTAL_LOGIN_URL}>
              Login
            </Button>
            <Box paddingX={1}>
              <Typography variant="body1">
                or
              </Typography>
            </Box>
            <Button variant="outlined" href={INWIN_PORTAL_SIGNUP_URL}>
              Register
            </Button>
          </Box>
          <Typography variant="body1" >
            to become a philanthropist
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box paddingX={1} marginTop={-1}>
              <Typography> You can also: </Typography>
            </Box>
            <Link
              color={"inherit"}
              underline={"none"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={"https://play.google.com/store/apps/details?id=com.discotech.inwin"}
            >
              <Image ns="apps" name="playstore" className={styles.appsIcon} />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
